import React, { useState } from 'react';
import axios from 'axios';
import { UncontrolledPopover, PopoverBody, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getCookie } from '../jwt/_helpers/cookie';
import './flaggedContent.css';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const FlaggedContent = ({
  id,
  name,
  has_flagged_tracks,
  flagged_tracks,
  is_light_whitelisted,
  action,
}) => {
  const tokenAPI = getCookie("token");
  const options = {
    method: "POST",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${tokenAPI}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };
  const [modal, setModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const { forceUpdate, setForceUpdate } = action;

  const toggleModal = () => setModal(!modal);

  const handlePayment = async () => {
    setIsProcessing(true);
    setIsCompleted(false);
    try {
      const response = await axios.post(`${API_URL}process-flagged-tracks-payment/`, {"release_id": id}, options);
  
      if (response.status === 200 || response.status === 201) {
        setIsProcessing(false);
        setIsCompleted(true);
        setTimeout(() => {
          setForceUpdate(forceUpdate + 1);
        }, (2000));
      } else {
        console.error("Error occurred during payment");
      }
    } catch (error) {
      setIsProcessing(false);
      console.error("Error occurred during payment:", error);
    }
  };
  

  const rowId = `row-flagged-content-${id}`;
  const showStatusNote = has_flagged_tracks || is_light_whitelisted;
  const bellColor = is_light_whitelisted ? 'green' : (has_flagged_tracks ? 'red' : '#4f5467');

  const totalAmount = Object.keys(flagged_tracks).length * 15;

  const statusNoteText = is_light_whitelisted
    ? <>Release <b>{name}</b> has been added to the whitelist.</>
    : (has_flagged_tracks ? (
      <>
        Release <b>{name}</b> has flagged tracks. You need to pay <b>15 euros</b> for every flagged track in order to deliver this release to Spotify.<br/>
        <center>
          <span
            onClick={toggleModal}
            style={{ 
              textDecoration: 'underline', 
              cursor: 'pointer', 
              color: 'blue', 
              fontWeight: 'bold' 
            }}
          >
            View Details
          </span>
        </center>
      </>
    ) : null);

  return (
    <div>
      <i 
        className={"fas fa-bell fa-2x flagged-content-icon" + (showStatusNote ? " flagged-content-avl-icon" : "")}
        id={rowId} 
        aria-hidden="true" 
        style={{ color: bellColor }}
        disabled={!showStatusNote}
      ></i>

      <UncontrolledPopover trigger="legacy" id="flagged-content-popover" placement="bottom" target={rowId} disabled={!showStatusNote}>
        <PopoverBody style={{ padding: "0px" }}>
          <Alert
            color={is_light_whitelisted? "success": "danger"}
            style={{ fontSize: "14px", fontWeight: "bolder", margin: "0px", cursor: "pointer" }}
            fade={true}
          >
            {statusNoteText}
          </Alert>
        </PopoverBody>
      </UncontrolledPopover>

      <Modal isOpen={modal} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{(!isCompleted && !isProcessing)? "Flagged Tracks Details": "Flagged Track Payment"}</ModalHeader>
        <ModalBody>
          {
            (!isCompleted && !isProcessing) && 
            <>
              <div className="mb-3">
                The following tracks have been flagged. Please review the details below:
              </div>

              {flagged_tracks && Object.keys(flagged_tracks).length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track Name</th>
                      <th>Payable Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(flagged_tracks).map((track, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{track.name}</td>
                        <td><b>15 €</b></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="mt-4 mb-4">
                In order to deliver this release to Spotify, you need to pay a total of <b>{totalAmount} €</b> for whitelisting. Click the button below to complete the payment.
                <br/><br/>
                By clicking the <b>Proceed To Payment</b> button below, you acknowledge and agree that an escrow amount of <b>{totalAmount} €</b> will be deposited by you. This amount will be held for a period of <b>six (6) months</b> and, provided no issues arise, will be returned to you at the end of this period. However, if you violate the terms of this agreement during this time, the escrow amount will be applied to the outstanding invoice and will not be refunded. By proceeding, you affirm your understanding and acceptance of these conditions.
              </div>            
            </>
          }
          <div className="payment-animation">
            {isProcessing && (
              <div className="spinner-container">
                <div className="spinner"></div>
                <div className="processing-text">Processing Payment...</div>
              </div>
            )}

            {isCompleted && (
              <div className="success-container">
                <div className="success-checkmark">
                  <div className="check-icon">
                    <div className="icon-circle"></div>
                    <div className="mdi mdi-check mdi-48px"></div>
                  </div>
                </div>
                <div className="success-text">Payment Successful!</div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {
            !isCompleted && !isProcessing && <>
              <Button color="success" onClick={handlePayment} disabled={isProcessing}>
                Proceed to Payment
              </Button>
              <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </>
          }
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FlaggedContent;