import React from "react";
import { Col, Row, Input, Button } from "reactstrap";
import moment from "moment";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toFloat } from "validator";

const EscrowPaymentsTable = ({ tableData, searchQuery, defaultSorted }) => {
  const { ExportCSVButton } = CSVExport;

    const dateFormatter = (cell, row) => {
      return (
        moment(cell).format('YYYY-MM-DD')
      );
    };

    const amountFormatter = (cell, row) => (
        <b>{toFloat(cell).toFixed(0)} €</b>
    );

    const downloadFormatter = cell =>
      cell ? (
        <a href={cell} target="blank">
          <Button className="btn-status" color="primary">
            <b>Download</b>
          </Button>
        </a>
      ) : null;

      const refundProcessedFormatter = (cell, row) =>
        cell ?
        (
          <span className="p-4">
            <i className="fas fa-2x fa-check-circle text-success" id={`${row.id}`}></i>
          </span>
        ) :     
        (
          <span className="p-4">
            <i className="fas fa-2x fa-times-circle text-danger" id={`${row.id}`}></i>
          </span>
        );

  const columns = [
    {
      dataField: "payment_id",
      text: "Payment ID",
      sort: false,
      headerStyle: () => ({ width: "120px" }),
    },
    {
      dataField: "track_name",
      text: "Track",
      sort: false,
      headerStyle: () => ({ width: "120px" })
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: amountFormatter,
      csvFormatter: amountFormatter,
      sort: false,
      headerStyle: () => ({ width: "120px" })
    },
    {
      dataField: "created",
      text: "Payment Date",
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
      sort: true,
      headerStyle: () => ({ width: "120px" })
    },
    {
      dataField: "agreement_pdf",
      text: "Payment Agreement",
      formatter: downloadFormatter,
      csvFormatter: downloadFormatter,
      sort: false,
      headerStyle: () => ({ width: "100px" })
    },
    {
      dataField: "refund_processed",
      text: "Refund Processed",
      formatter: refundProcessedFormatter,
      sort: false,
      headerStyle: () => ({ width: "100px" })
    },
    {
      dataField: "track_name",
      sort: false,
      csvExport: false,
      hidden: true,
    }
  ];

  const handleTableChange = (type, { sortField, sortOrder }) => {
    const ascDesc = sortOrder === "desc" ? "-" : "";
    if (sortField === "created") {
      searchQuery.setOrdering({ order: ascDesc, name: sortField, sort: sortField });
    }
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={tableData}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      exportCSV={{ fileName: "escrow-payments.csv" }}
    >
      {props => (
        <div>
          <Row>
            <Col>
              <div className="customSearch">
                <Input
                  placeholder="Search..."
                  value={searchQuery.query}
                  style={{ marginBottom: 10 }}
                  onChange={e => searchQuery.setQuery(e.target.value)}
                />
              </div>
            </Col>

            <Col>
              <div className="flexEndContainer">
                <ExportCSVButton className="btn btn-outline-info" {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
              </div>
            </Col>
          </Row>
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            remote={{ sort: true }}
            defaultSorted={defaultSorted}
            onTableChange={handleTableChange}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default EscrowPaymentsTable;