import React, { useContext } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import EscrowPayments from "./escrowPayments";
import EscrowRefunds from "./escrowRefunds";
import { StateContext } from "../../utils/context";

import "./escrowWallet.scss";

const EscrowWallet = () => {
    const { currentUser } = useContext(StateContext);

    return (
        <>
            <Row className="mb-3">
                <Col xm="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xm="12" sm="8" md="8" lg="8" xl="10">
                                    <CardTitle>
                                        <h4>Manage Your Escrow Wallet</h4>
                                    </CardTitle>
                                    Funds are withheld to ensure safety and transparency during the verification process. For flagged tracks, a fee of <b>15 €</b> per track is held in escrow to whitelist the track for delivery to Spotify. These funds will be refunded once the track’s artists are verified as legitimate.
                                    <br /><br />
                                    Use the table below to view all escrow transactions. For more details, <a href="#" target="_blank" rel="noopener noreferrer"><b>Learn More</b></a>

                                </Col>
                                <Col xm="12" sm="2" md="2" lg="2" xl="1">
                                    <center><h6>Total Withheld</h6><br /><h4 className="text-danger">{currentUser.escrow_total_withheld} €</h4></center>
                                </Col>
                                <Col xm="12" sm="2" md="2" lg="2" xl="1">
                                    <center><h6>Total Refunds</h6><br /><h4 className="text-success">{currentUser.escrow_total_refunds} €</h4></center>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col xm="12" sm="12" md="12" lg="12" xl="12">
                    <EscrowPayments />
                </Col>
            </Row>


            <Row className="mt-2">
                <Col xm="12" sm="12" md="12" lg="12" xl="12">
                    <EscrowRefunds />
                </Col>
            </Row>
        </>
    );
};

export default EscrowWallet;