import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import Pagination from '../../components/pagination/pagination';
import EscrowRefundsTable from "../../components/escrowRefundsTable";
import { useFetch } from "../../utils/fetchHook";
import { getCookie } from "../../jwt/_helpers/cookie";

const EscrowRefunds = () => {

    const token = getCookie("token");

    const didEff1 = useRef(false);
    const didEff2 = useRef(false);

    const [forceUpdate, setForce] = useState(1);

    const [tableData, setTableData] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [currrentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [query, setQuery] = useState('');
    const [ordering, setOrdering] = useState({ order: '-', name: 'created', sort: 'created' });

    const [apiData, error, loading] = useFetch("GET", `track-escrow-refunds/?ordering=${ordering.order + ordering.name}&page=${currrentPage}&page_size=${pageSize}&search=${query}`, token, false, forceUpdate);

    useEffect(() => {
        if (apiData.results) {
            setTableData(apiData.results);
        }
        if (apiData.count) {
            setDataCount(apiData.count);
        }
    }, [apiData]);

    useEffect(() => {
        if (!didEff1.current) {
            didEff1.current = true;
            return;
        }
        setForce(prev => prev + 1);
    }, [currrentPage, pageSize, ordering.order, ordering.name]);

    useEffect(() => {
        if (!didEff2.current) {
            didEff2.current = true;
            return;
        }
        const timeout = setTimeout(() => {
            setForce(prev => prev + 1);
        }, 500);
        return () => clearTimeout(timeout);
    }, [query]);

    const paginationProps = {
        pageSize, setPageSize, dataCount, setCurrentPage, currrentPage
    };

    const defaultSorted = [{
        dataField: ordering.sort,
        order: ordering.order === "-" ? "desc" : "asc"
    }];

    const tablrops = {
        tableData,
        defaultSorted
    };

    return (
        <Card>
            <CardBody>
                <CardTitle>
                    <h4>Escrow Refunds</h4>
                </CardTitle>
                <EscrowRefundsTable {...tablrops} searchQuery={{ setQuery, query, ordering, setOrdering }} />
                <Pagination pagination={paginationProps} />
            </CardBody>
        </Card>
    );
};

export default EscrowRefunds;